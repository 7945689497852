import React from 'react';

import Layout from '../components/layout';
import Head from '../components/head';

const ContactPage = () => {
  return (
    <Layout>
      <Head title="Contact"/>
      <h1 className="mb-6 text-3xl font-bold">Contact Me</h1>
      <ul className="pl-6 list-disc underline">
        <li><a
          href="https://www.linkedin.com/in/jared-kaneshiro-9b83322b/"
          target="_blank"
          rel="noreferrer"
          className="text-teal-500 hover:text-teal-800"
        >LinkedIn</a>
        </li>
        <li><a
          href="https://github.com/jmkaneshiro"
          target="_blank"
          rel="noreferrer"
          className="text-teal-500 hover:text-teal-800"
        >Github</a></li>
        <li><a
          href="https://angel.co/u/jared-kaneshiro-1"
          target="_blank"
          rel="noreferrer"
          className="text-teal-500 hover:text-teal-800"
        >AngelList</a></li>
        <li><a
          href="mailto:jmkaneshiro@gmail.com?Subject=Interested%20Recruiter%20Or%20Hiring%20Manager"
          target="_blank"
          rel="noreferrer"
          className="text-teal-500 hover:text-teal-800"
        >jmkaneshiro@gmail.com</a></li>
      </ul>
    </Layout>
  )
}

export default ContactPage;